import { observer } from "mobx-react-lite";

import store from "../store/data";
function Prices() {
  return (
    <>
      <div id={"prices"} className="prices-container">
        <div className="wrapper-container">
          <h2 className="container__title">{store.data.table[0][6]}</h2>
          <table id="technics">
            <tr>
              <th>{store.data.table[0][0]}</th>
              <th>{store.data.table[0][7]}</th>
            </tr>
            {store.data.prices[0].map((item) => (

            <tr>
              <td>{item.name}</td>
              <td className="table__price">od {item.price} Zł</td>
            </tr>
            ))}
         
            
          </table>
          <table>
            <tr>
              <th>{store.data.table[0][1]}</th>
              <th>{store.data.table[0][7]}</th>
            </tr>
            {store.data.prices[1].map((item) => (

            <tr>
              <td>{item.name}</td>
              <td className="table__price">od {item.price} Zł</td>
            </tr>
            ))}
         
            
          </table>
          <table >
            <tr>
              <th>{store.data.table[0][2]}</th>
              <th>{store.data.table[0][7]}</th>
            </tr>
            {store.data.prices[2].map((item) => (

            <tr>
              <td>{item.name}</td>
              <td className="table__price">od {item.price} Zł</td>
            </tr>
            ))}
         
            
          </table>
          <table>
            <tr>
              <th>{store.data.table[0][3]}</th>
              <th>{store.data.table[0][7]}</th>
            </tr>
            {store.data.prices[3].map((item) => (

            <tr>
              <td>{item.name}</td>
              <td className="table__price">od {item.price} Zł</td>
            </tr>
            ))}
         
            
          </table>
          <table>
            <tr>
              <th>{store.data.table[0][4]}</th>
              <th>{store.data.table[0][7]}</th>
            </tr>
            {store.data.prices[4].map((item) => (

            <tr>
              <td>{item.name}</td>
              <td className="table__price">od {item.price} Zł</td>
            </tr>
            ))}
         
            
          </table>
          <table >
            <tr>
              <th>{store.data.table[0][5]}</th>
              <th>{store.data.table[0][7]}</th>
            </tr>
            {store.data.prices[5].map((item) => (

            <tr>
              <td>{item.name}</td>
              <td className="table__price">od {item.price} Zł</td>
            </tr>
            ))}
         
            
          </table>
        </div>
      </div>
    </>
  );
}

export default observer (Prices);
