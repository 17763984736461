import { useEffect, useState } from "react";
import store from "../store/data";

import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import { observer } from "mobx-react-lite";
import postData from "../mailer/Service";
const { Option } = Select;
const { TextArea } = Input;

function ContactForm() {
  const theme = {
    components: {
      Input: {
        colorTextPlaceholder: "#848484",
      },
      Select: {
        colorTextPlaceholder: "#848484",
        controlHeight: 38,
      },
      TextArea: {
        controlHeight: 138,
      },
    },
  };
  const FontsStyles = {
    height: "38px",
  };

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isBlockVisible, setBlockVisibility] = useState(true);
  const handleCloseClick = () => {
    setBlockVisibility(false);
  };

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);

    postData(values);
  };
  const [isPulsating, setPulsating] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPulsating((prev) => !prev);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {isBlockVisible && (
        <div className="contact-form">
          {" "}
          <div className="phone-wrap">
            <a href="tel:+48578074460">
              <svg
                className="phone"
                width="47"
                height="49"
                viewBox="0 0 47 49"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.59 2.035c.52-.398 1.025-.814 1.55-1.208C9.685-.334 11.308-.266 12.776.99c1.24 1.06 2.168 2.37 3.01 3.721.93 1.491 1.83 3.016 2.552 4.61.933 2.06.539 3.99-1.182 5.55a35.828 35.828 0 0 1-3.007 2.434c-1.558 1.131-2.119 2.564-1.622 4.408.51 1.888 1.508 3.531 2.694 5.074 2.138 2.782 4.466 5.394 7.299 7.536 1.038.785 2.157 1.412 3.457 1.72 1.358.32 2.494-.034 3.5-.922 1.15-1.013 2.248-2.09 3.465-3.02 1.53-1.169 3.293-1.359 5.006-.458 1.321.695 2.59 1.53 3.748 2.462 1.439 1.157 2.773 2.445 4.082 3.745.433.43.685 1.053.953 1.618.477 1.008.307 2.015-.43 2.792-2.193 2.309-4.541 4.44-7.617 5.632-2.965 1.15-6.007 1.376-9.131.816-4.259-.762-7.907-2.773-11.285-5.3-4.616-3.45-8.44-7.645-11.84-12.215-2.197-2.952-4.047-6.098-5.251-9.571-.743-2.143-1.212-4.336-1.176-6.743-.055-5.282 2.27-9.543 6.588-12.843z"
                  fill="#FFF"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
          </div>
          <p className="contact-form__title">{store.data.titles[3]}</p>
          <p className="contact-form__subtitle">{store.data.titles[4]}</p>
          <div className="close-button-wrap">
            <div onClick={handleCloseClick} className="close-button">
              <span></span>
            </div>
          </div>
          <ConfigProvider theme={theme}>
            <Form
              className="footer-forms"
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
            >
              <div className="contact-form__wrap">
                <Form.Item
                  name="nickname"
                  rules={[
                    {
                      required: true,
                      message: "Enter your name",
                      whitespace: true,
                    },
                  ]}
                  style={FontsStyles}
                >
                  <Input
                    style={FontsStyles}
                    placeholder={store.data.forms[0]}
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  style={FontsStyles}
                  rules={[
                    { required: true, message: "Enter your telephone number" },
                  ]}
                >
                  <Input
                    placeholder="+48 123 456 78 90*"
                    style={{ height: "38px", width: "100%" }}
                  />
                </Form.Item>
                <Form.Item name="services">
                  <Select placeholder={store.data.forms[1]}>
                    <Option value={store.data.forms[2]}>
                      {store.data.forms[2]}
                    </Option>
                    <Option value={store.data.forms[3]}>
                      {store.data.forms[3]}
                    </Option>
                    <Option value={store.data.forms[4]}>
                      {store.data.forms[4]}
                    </Option>
                    <Option value={store.data.forms[5]}>
                      {store.data.forms[5]}
                    </Option>
                    <Option value={store.data.forms[6]}>
                      {store.data.forms[6]}
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Should accept agreement")
                            ),
                    },
                  ]}
                >
                  <Checkbox style={{ color: "#fff" }}>
                    I have read the{" "}
                    <i
                      style={{ color: "#EBCF5F" }}
                      onClick={() => setOpen(true)}
                    >
                      agreement
                    </i>
                  </Checkbox>
                </Form.Item>
                <Modal
                  title="Danych osobowych"
                  centered
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={600}
                >
                  <p>
                    Administracja zobowiązuje się do zachowania Twojej
                    prywatności w Internecie. Przywiązujemy dużą wagę do ochrony
                    przekazywanych przez Ciebie danych. Nasza polityka
                    prywatności opiera się na wymogach ogólnego rozporządzenia
                    Unii Europejskiej o ochronie danych (RODO). Cele, dla
                    których zbieramy dane osobowe to: poprawa wydajności naszego
                    serwisu, komunikacja z gośćmi tej strony, wysyłanie
                    biuletynów pocztą elektroniczną, przetwarzanie danych
                    użytkownika w ramach usługi handlu online, dostarczanie
                    informacji wymaganych przez użytkownika, jak również za
                    następujące działania. Gromadzenie i wykorzystywanie danych
                    osobowych Gromadzimy i wykorzystujemy Twoje dane osobowe
                    wyłącznie za Twoją dobrowolną zgodą. Jeśli wyrażasz na to
                    zgodę, upoważniasz nas do gromadzenia i wykorzystywania
                    następujących danych: imię i nazwisko, adres e-mail, numer
                    telefonu, . Zbieranie i przetwarzanie Twoich danych odbywa
                    się zgodnie z przepisami prawa obowiązującymi na terenie
                    Unii Europejskiej oraz w Rzeczypospolitej Polskiej.
                    Przechowywanie, modyfikacja i usuwanie danych Użytkownik,
                    który udostępnił swoje dane osobowe w serwisie alma-auto.pl
                    ma prawo do ich zmiany i usunięcia, a także wycofania zgody
                    na ich wykorzystanie. Okres przechowywania Twoich danych
                    osobowych: 6 miesięcy. Po zakończeniu korzystania z Twoich
                    danych administracja serwisu je usuwa. Twoje dane osobowe
                    możemy przekazać podmiotowi trzeciemu tylko za Twoją
                    dobrowolną zgodą, jeżeli zostały przekazane, to nie możemy
                    zmieniać danych w innych, niepowiązanych z nami
                    organizacjach. Wykorzystanie danych technicznych podczas
                    odwiedzania strony Gdy odwiedzasz witrynę alma-auto.pl, w
                    bazie danych rejestrowany jest Twój adres IP, czas wizyty,
                    ustawienia przeglądarki, system operacyjny oraz inne
                    informacje techniczne niezbędne do prawidłowego wyświetlania
                    zawartości witryny. Na podstawie tych danych nie jesteśmy w
                    stanie zidentyfikować tożsamości odwiedzającego. Udzielanie
                    informacji przez dzieci Jeśli jesteś rodzicem lub opiekunem
                    i wiesz, że Twoje dzieci przekazały nam swoje dane osobowe
                    bez Twojej zgody, skontaktuj się z nami:
                    kontakt@alma-auto.pl. W naszym serwisie zabronione jest
                    pozostawianie danych osobowych osób nieletnich bez zgody
                    rodziców lub opiekunów. Korzystanie z plików cookie Używamy
                    plików cookies, aby zapewnić prawidłowe wyświetlanie treści
                    oraz wygodę korzystania z alma-auto.pl. Są to małe pliki,
                    które są przechowywane na Twoim urządzeniu. Pomagają
                    witrynie zapamiętać informacje o Tobie, takie jak język, w
                    jakim przeglądasz witrynę i jakie strony już otworzyłeś,
                    informacje te będą przydatne przy następnej wizycie. Dzięki
                    plikom cookies przeglądanie serwisu staje się dużo
                    wygodniejsze. Możesz przeczytać więcej o tych plikach tutaj.
                    Możesz samodzielnie skonfigurować swoją przeglądarkę tak,
                    aby akceptowała lub blokowała pliki cookie. Brak akceptacji
                    plików cookies może ograniczyć funkcjonalność serwisu.
                    Wykorzystanie danych osobowych przez inne usługi Ta strona
                    korzysta z zewnętrznych usług internetowych, które zbierają
                    informacje niezależnie od nas: Google Analytics,
                    Yandex.Metrica, Facebook.com. Gromadzone przez nich dane
                    mogą być udostępniane innym usługom w ramach tych
                    organizacji i mogą wykorzystywać te dane do personalizowania
                    reklam z własnej sieci reklamowej. Możesz przeczytać umowy
                    użytkownika tych organizacji na ich stronach internetowych.
                    Tam możesz również odmówić zbierania od nich danych
                    osobowych, na przykład tutaj znajduje się bloker Google
                    Analytics, tutaj znajduje się bloker Yandex Metrics. Nie
                    udostępniamy danych osobowych innym organizacjom i usługom
                    niewymienionym w niniejszej polityce prywatności. Jedynym
                    wyjątkiem jest przekazywanie informacji w ramach wymogów
                    prawnych organów państwowych uprawnionych do wykonywania
                    tych czynności. Linki do innych stron Nasza typowa witryna
                    alma-auto.pl może zawierać linki do innych witryn, które nie
                    są przez nas obsługiwane. Nie ponosimy odpowiedzialności za
                    ich treść. Zachęcamy do zapoznania się z polityką
                    prywatności każdej odwiedzanej witryny, jeśli jest dostępna.
                    Zmiany w polityce prywatności Nasza witryna internetowa
                    alma-auto.pl może od czasu do czasu aktualizować naszą
                    politykę prywatności. Powiadomimy Cię o wszelkich zmianach,
                    publikując nową politykę prywatności na tej stronie.
                    Monitorujemy zmiany w ustawodawstwie dotyczącym danych
                    osobowych w Unii Europejskiej oraz w Polsce. Jeżeli
                    pozostawiłeś nam dane osobowe, poinformujemy Cię o zmianie
                    polityki prywatności. Jeśli Twoje dane osobowe zostały
                    wprowadzone błędnie, nie będziemy mogli się z Tobą
                    skontaktować.
                  </p>
                </Modal>
                <Form.Item>
                  <Button
                    style={{
                      color: "#000",
                      backgroundColor: "#EBCF5F",
                      fontWeight: 600,
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    {store.data.buttons[3]}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </ConfigProvider>
        </div>
      )}
    </>
  );
}

export default ContactForm;
