import React from "react";
import Forms from "./Forms";
import Contacts from "./Contacts";
import store from "../store/data";
import { observer } from "mobx-react-lite";

function Footer() {
  return (
    <div className="footer">
      <div className="form-container">
        <div className="wrapper-container">
          <h2 className="footer-form_title">{store.data.titles[3]}</h2>
          <p className="footer-form_subtitle">
          {store.data.titles[4]}
          </p>

          <Forms />
        </div>
      </div>

      <div className="contacts-wrapper">
        <h2 className="container__title_lowercase">{store.data.titles[5]}</h2>
        <p className="container__subtitle">
      {store.data.office[0]}
      </p>
      <p className="container__subtitle">
      {store.data.office[1]}
      </p>
        <Contacts />
      </div>

      <div className="copyright-container">
        <p>
        {store.data.footer[0]}
        </p>
        <p>
          © 2023 Car King | Samochodowe instalacje gazowe - sprzedaż,
          montaż, serwis | Serwis samochodowy Szczecin
        </p>
      </div>
    </div>
  );
}

export default observer (Footer);
