import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "antd";
import {
  Navbar,
  About,
  Homepage,
  Footer,
  Prices,
  Gas,
} from "./components/index";
import ContactForm from "./components/ContactForm";

function App() {
  return (
    <>
      <header className="header">
        <Navbar />
      </header>
      <ContactForm/>
      <main className="main">
        <Layout>
          <div className="routes">
            <Routes>
              <Route path="/" element={<Homepage />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/gas" element={<Gas />}></Route>
              <Route path="/prices" element={<Prices />}></Route>
            </Routes>
          </div>
          {/* <div className="popUp">
            <p>Awans! Wymiana oleju 70 zł netto</p>
          </div> */}
        </Layout>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
