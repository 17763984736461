async function postData(values) {
    let jsonData = JSON.stringify(values);
  let response = await fetch("../../send.php", {

    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
 
    body: jsonData,
  });
  if (response.ok) {
    alert("Your report has been successfully submitted!");
  } else {
    alert("Something wrong!");
  }
}
export default postData;
