const db = [
  { lang: "eng" },
  { lang: "pl" },
  {
    lang: "ua",
    data: {
      nav: [
        "головна",
        "про нас",
        "послуги",
        "ціна",
        "газові установки",
        "контакти",
      ],
      slider: [
        [
          "автосервіс",
          "гарантія якості",
          "Дігностика, ремонт, шиномонтаж",
          "філія у Штецині",
          "Зручне розташування",
        ],
        ['Встановлення ГБО','почніть економити на паливі до 50%','Гарантія 2 роки без обмеження пробігу на встановлене ГБО'],
        ['ЗБІЛЬШИЛАСЯ ВИТРАТА ПАЛИВА,','ЗНИЗИЛАСЯ ПОТУЖНІСТЬ?','ПЕРЕВІРТЕ ФІЛЬТР DPF!'],
      ],
      buttons: [
        "записатися на консультацію",
        "залишити заявку",
        "записатися на прийом",
      ],

      footer: [
        {
          license:
            "Співробітники нашої компанії доклали всіх можливих зусиль, щоб інформація, представлена в прайс-листі, була достовірною і актуальною, проте можливі невеликі затримки в оновленні даних. Тому некоректна інформація на сайті не може бути підставою для претензій. У разі будь-яких сумнівів, будь ласка, зв'яжіться зі співробітником компанії перед прийняттям рішення. Пропозиція, представлена в прайс-листі, не є офертою в розумінні Цивільного кодексу, а тільки запрошенням до початку переговорів (відповідно до статті 71 Цивільного кодексу).",
        },
        {
          copyright:
            "© 2023 Alma Auto Centrum | Samochodowe instalacje gazowe - sprzedaż, montaż, serwis | Serwis samochodowy Szczecin",
        },
      ],
    },
  },
];
export {db}