import { observer } from "mobx-react-lite";
import store from "../store/data";
import { Link } from "react-router-dom";
import { Image } from "antd";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

function Menu({  items, active, setMenuActive }) {
  return (
    <div
      className={active ? "menu active" : "menu"}
      onClick={() => setMenuActive(false)}
    >
      <div className="blur"></div>
      <div className="menu__content" onClick={(e) => e.stopPropagation()}>
       
        <ul>
          <li>
          <div className="logo-container">
          <Link to="/">
            <Image preview={false} width={90} src="./images/logo.png" />
          </Link>
        </div>
          </li>
          <li>
            <Link className="menu-item" to="/#">
              {store.data.nav[0]}
            </Link>
          </li>
          <li>
            <a className="menu-item" href="#services">
              {store.data.nav[1]}
            </a>
          </li>
          <li>
            <Link className="menu-item" to="/prices#">
              {store.data.nav[2]}
            </Link>
          </li>
          <li>
            <a className="menu-item" href="#contacts">
              {store.data.nav[3]}
            </a>
          </li>
          <li>
              <div className="social-icons social-icons_mobile">
                <a href="https://www.facebook.com/profile.php?id=100091648115089">
                  <FacebookOutlined />
                </a>

                <a href="https://www.instagram.com/carking.pl/?fbclid=IwAR1Od2V6fSinqWrkfDlRP8ita8twTT4qC0pYXBcHPdC4LGIrVaHq6dFiA9k">
                  <InstagramOutlined />
                </a>
              </div>
            </li>
            <li>
              <div className="telephone-container">
                <div className="">
                  <a href="tel:+48791784406">+48 791 784 406</a>
                  <a href="tel:+48578074460">+48 578 074 460</a>
                </div>
                <div className="">
                  <a href="#map_way">Mieszka I 21, 71-011 Szczecin</a>
                </div>
              </div>
            </li>
        </ul>
      </div>
    </div>
  );
}
export default observer(Menu);
