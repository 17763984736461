
import Slider from "./Slider";
import CardsServices from "./CardsServices";

import OfficeGalery from "./OfficeGalery";

function Homepage() {
  return (
    <div>
      <Slider />
      <div className="wrapper-container">
        <CardsServices />
        {/* <Gas/> */}
        {/* <OfficeGalery/> */}

    

    
      </div>
    </div>
  );
}

export default Homepage;
