import { observer } from "mobx-react-lite";
import React from "react";
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import store from "../store/data";

function CardsServices() {
  return (
    <>
      <h2 id={"services"} className="container__title">{store.data.titles[0]}</h2>
      <p className="container__subtitle">
      {store.data.services[0]}
      </p>
      <div className="service-cards-container">
        <div className="service-card">
          <img src="./images/cards/card-1.png" alt="img" />

          <div className="service-card__descr">
            <div className="service-card__title">
              {store?.data?.cardsServices[0][0]}
            </div>
            <p>{store?.data?.cardsServices[0][1]}</p>
            <p>{store?.data?.cardsServices[0][2]}</p>
            <p>{store.data?.cardsServices[0][3]}</p>
            <p>{store?.data?.cardsServices[0][4]}</p>
            <p>{store?.data?.cardsServices[0][5]}</p>
            <Link to="/prices#technics">
              <p className="service-card__link">{store.data.buttons[4]}</p>
            </Link>
          </div>
        </div>
        <div className="service-card">
          <img src="./images/cards/card-2.png" alt="img" />

          <div className="service-card__descr">
            <div className="service-card__title">
              {store?.data?.cardsServices[1][0]}
            </div>{" "}
            <p>{store?.data?.cardsServices[1][1]}</p>
            <p>{store?.data?.cardsServices[1][2]}</p>
            <p>{store?.data?.cardsServices[1][3]}</p>
            <p>{store?.data?.cardsServices[1][4]}</p>
            <p>{store?.data?.cardsServices[1][5]}</p>
            <Link to="/prices">
              <p className="service-card__link">{store.data.buttons[4]}</p>
            </Link>
          </div>
        </div>
        <div className="service-card">
          <img src="./images/cards/card-3.png" alt="img" />

          <div className="service-card__descr">
            <div className="service-card__title">
              {store?.data?.cardsServices[2][0]}
            </div>
            <p>{store?.data?.cardsServices[2][1]}</p>
            <p>{store?.data?.cardsServices[2][2]}</p>
            <p>{store?.data?.cardsServices[2][3]}</p>

            <Link to="/prices">
              <p className="service-card__link">{store.data.buttons[4]}</p>
            </Link>
          </div>
        </div>
        <div className="service-card">
          <img src="./images/cards/card-4.png" alt="img" />

          <div className="service-card__descr">
            <div className="service-card__title">
              {store?.data?.cardsServices[3][0]}
            </div>
            <p>{store?.data?.cardsServices[3][1]}</p>

            <Link to="/prices">
              <p className="service-card__link">{store.data.buttons[4]}</p>
            </Link>
          </div>
        </div>
        <div className="service-card">
          <img src="./images/cards/card-5.png" alt="img" />

          <div className="service-card__descr">
            <div className="service-card__title">
              {store?.data?.cardsServices[4][0]}
            </div>

            <Link to="/prices">
              <p className="service-card__link">{store.data.buttons[4]}</p>
            </Link>
          </div>
        </div>
        <div className="service-card">
          <img src="./images/cards/card-6.png" alt="img" />

          <div className="service-card__descr">
            <div className="service-card__title">
              {store?.data?.cardsServices[5][0]}
            </div>{" "}
            <Link to="/prices">
              <p className="service-card__link">{store.data.buttons[4]}</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(CardsServices);
