import { observer } from "mobx-react-lite";
import store from "../store/data";

function Contacts() {
  return (
    <div className="contacts-container">
      
      <div id="contacts" className="contacts-content">
        <div id="map_way" className="content__map">
        
        <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d999.9079032281552!2d14.51909997186266!3d53.411335332847976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aa08da26ec8bab%3A0x8cac92ebede4f78c!2zTWllc3prYSBJIDIxLCA3MS0wMTEgU3pjemVjaW4sINCf0L7Qu9GM0YnQsA!5e0!3m2!1suk!2sua!4v1698261464776!5m2!1suk!2sua" width="320" height="450"   loading="lazy"></iframe>

        </div>
        <div className="content__descr">
          <h3 className="desc__title">Car King Szczecin</h3>
          <div className="desc-item">
            <a className="desc-item__phone" href="tel:+48791784406">
            +48 791 784 406
            </a>
            <a className="desc-item__phone"  href="tel: +48578074460">
            +48 578 074 460
            </a>
          
          </div>
          <div className="desc-item">
            <p>Mieszka I 21, 71-011 Szczecin</p>
          </div>
          <div className="desc-item desc-item_work-hours">
            <p>{store?.data?.office[4]} - {store?.data?.office[5]} 9:00 – 18:00</p>
            <p>{store.data.office[6]} 9:00 – 14:00</p>
          </div>
          <div className="desc-item">
            <a href="mailto:kontact@car-king.pl">kontact@car-king.pl</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer (Contacts);
