import { Carousel } from "antd";
import store from "../store/data";
import { observer } from "mobx-react-lite";

const Slider = observer(() => {
  const settings = {
    autoplay: true,
    dots: true,
    fade: true,
    infinite: true,
    swipeToSlide: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="slider-container">
      <div className="slider-hero">
        <Carousel {...settings}>
          <div className="slide-card">
            <img src="./images/slider/slide-1.png" alt="img" />
           {/*  <img src="./images/slider/test.png" alt="img" /> */}
            <div className="slider-descr">
              <div className="slider-descr__title_first">
                <h1>
                  {store.data.slider[0][0].split(' ').slice(0,2).join(' ')} <br/>
                  {store.data.slider[0][0].split(' ').slice(2,3).join(' ')}
                
                </h1>
              </div>
              {/* <div className="slider-descr__subtitle">
                <div>
                  {store.data.slider[0][1]} <p>{store.data.slider[0][2]}</p>
                </div>
                <div>
                  {store.data.slider[0][3]} <p>{store.data.slider[0][4]}</p>
                </div>
              </div> */}
              <div className="slider-descr__buttons">
                <a href="#contacts">
                  <button>{store.data.buttons[0]}</button>
                </a>
              </div>
            </div>
          </div>
          <div className="slide-card">
            <img src="./images/slider/slide-2.jpg" alt="img" />
            <div className="slider-descr">
              <div className="slider-descr__title">
                <h1>{store.data.slider[1][0]}</h1>
              </div>
              <div className="slider-descr__subtitle">
                <div>
                  {store.data.slider[1][1]} <p>{store.data.slider[1][2]}</p>
                </div>
              </div>
              <div className="slider-descr__buttons">
                <a href="#contacts">
                  <button>{store.data.buttons[0]}</button>
                </a>
              </div>
            </div>
          </div>
          <div className="slide-card">
            <img src="./images/slider/slide-3.jpg" alt="img" />
            <div className="slider-descr">
              <div className="slider-descr__title">
                <h1>
                  {store.data.slider[2][0]}
                  <br />
                  {store.data.slider[2][1]}
                  <br />
                  {store.data.slider[2][2]}
                  <br />
                  {store.data.slider[2][3]}
                </h1>
              </div>

              <div className="slider-descr__buttons">
                <a href="#contacts">
                  <button>{store.data.buttons[0]}</button>
                </a>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
});

export default Slider;
