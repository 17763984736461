import { useState } from "react";
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Image, Select } from "antd";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import store from "../store/data";
import Menu from "./Menu";
const Navbar = observer(() => {
  const [menuActive, setMenuActive] = useState(false);
  const items = [
    { value: "Home", href: "/main" },
    { value: "About us", href: "/about" },
    { value: "Contacts", href: "/contacts" },
  ];

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    store.switchLang(value);
  };
  return (
    <>
      <div className="nav-container_mobile">
        <div className="burger-btn" onClick={() => setMenuActive(!menuActive)}>
          <span />
        </div>
        <div className="logo-container">
          <Link to="/">
            <Image preview={false} width={90} src="./images/logo.png" />
          </Link>
        </div>
        <div className="select-container">
                <Select
                  defaultValue="pl"
                  style={{ width: 100 }}
                  onChange={handleChange}
                  options={[
                    { value: "pl", label: "Polski" },
                    { value: "ua", label: "Українська" },
                    { value: "eng", label: "English" },
                  ]}
                />
              </div>
        <Menu active={menuActive} setMenuActive={setMenuActive} items={items} />
      </div>
      <div className="nav-container">
        <nav className="nav">
          <div className="logo-container">
            <Link to="/#">
              <Image preview={false} width={90} src="./images/logo.png" />
            </Link>
          </div>
          <ul className="nav__list">
            <li>
              <Link className="menu-item" to="/#">
                {store.data.nav[0]}
              </Link>
            </li>
            <li>
              {" "}
              <a className="menu-item" href="/#services">
                {store.data.nav[1]}
              </a>
            </li>
            <li>
              <Link className="menu-item" to="/prices#prices">
                {store.data.nav[2]}
              </Link>
            </li>
            <li>
              <a className="menu-item" href="#contacts">
                {store.data.nav[3]}
              </a>
            </li>
            <li>
              <div className="social-icons">
                <a href="https://www.facebook.com/profile.php?id=100091648115089">
                  <FacebookOutlined />
                </a>

                <a href="https://www.instagram.com/carking.pl/?fbclid=IwAR1Od2V6fSinqWrkfDlRP8ita8twTT4qC0pYXBcHPdC4LGIrVaHq6dFiA9k">
                  <InstagramOutlined />
                </a>
              </div>
            </li>
            <li>
              <div className="telephone-container">
                <div className="">
                  <a href="tel:+48791784406">+48 791 784 406</a>
                  <a href="tel:+48578074460">+48 578 074 460</a>
                </div>
                <div className="">
                  <a href="#map_way">Mieszka I 21, 71-011 Szczecin</a>
                </div>
              </div>
            </li>
            <li>
              <div className="select-container">
                <Select
                  defaultValue="pl"
                  style={{ width: 100 }}
                  onChange={handleChange}
                  options={[
                    { value: "pl", label: "Polski" },
                    { value: "ua", label: "Українська" },
                    { value: "eng", label: "English" },
                  ]}
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
});

export default Navbar;
