import { makeAutoObservable } from "mobx";
import { db } from "../db.js";
class Data {
  ua = {
    nav: ["головна", "послуги", "прайс", "контакти"],
    slider: [
      [
        'Авто сервіс "CarKing"',
        "Гарантія якості",
        "Дігностика, ремонт, шиномонтаж",
        "Зручне розташування",
      ],
      ["Ремонт двигунів", "Ремонт двигунів будь-якої важкості"],
      ["Тюнінг", "- свап моторів", "- полірування фар", "- перешивка керма"],
    ],
    services: [
      "Сучасне обладнання нашої компанії дозволяє швидко діагностувати несправності і швидко усунути їх, давши гарантію якості. Технічний персонал має високу кваліфікацію, досвід і знання, необхідні для якісного ремонту будь-якої складності.",
    ],
    cardsServices: [
      [
        "Автомеханіка",
        "заміна мастила",
        "заміна фільтрів",
        "заміна ременя ГРМ",
      ],
      [
        "Обслуговування ходової частини",
        "діагностика",
        "заміна шарової опори",
        "заміна ричагів",
        "заміна колодок",
        "заміна сайлентблоків",
      ],
      ["Тюнінг", "полірування фар", "перешивка керма", "свапи"],
      ["Вулканизація", "шиномонтаж", "балансування"],
      ["Ремонт моторів будь-якої складності"],
      ["Заміна счеплення АКПП, РКПП"],
    ],
    popServices: [
      "Наша команда досвідчених механіків надає послуги з діагностики та ремонту двигуна. Ми оперативно виявляємо та усуваємо несправності, щоб ваш автомобіль працював як новий. Підтримуйте безпеку на дорозі з нашими послугами з шиномонтажу та балансування. Ми допоможемо вам правильно підібрати та встановити шини, щоб забезпечити максимальне зчеплення та комфорт під час руху.",
    ],
    office: [
      "Ми розуміємо, наскільки важливою є ваша безпека в подорожах, і знаємо, наскільки вам потрібен автомобіль щодня. Тому будь-який ремонт чи технічне обслуговування виконують лише досвідчені автомеханіки. Чекаємо на вас у нашому автосервісі у Szczecin! Щоб підтвердити час відвідування, ви можете залишити заявку на сайті або зателефонувати нам!",
      "Центр спеціалізується на послугах комплексного ремонту автомобілів, ремонту двигунів, тюнінгу, шиномонтажу.",
      "Спеціально для наших клієнтів ми підготували затишну зону, щоб ви могли комфортно відпочити і приємно провести час в очікуванні свого автомобіля.",
      "Час роботи",
      "Понеділок",
      "П'ятниця",
      "Субота",
      "Карта проїзду",
    ],
    buttons: [
      "детальніше про нас",
      "записатися на консультацію",
      "залишити заявку",
      "відправити",
      "дізнатись ціну",
    ],
    titles: [
      "наші послуги",
      "ПОПУЛЯРНІ ПОСЛУГИ",
      "Трохи про нас",
      "ЗАЛИШИТИ ЗАЯВКУ",
      "Залиште заявку, наш менеджер зв'яжеться з вами і відповість на всі питання.",
      "Контакти сервісу",
      "Прайс",
    ],
    forms: [
      "Ваше ім'я",
      "Вибір послуги",
      "Технічне обслуговування",
      "Полірування фар",
      "Обшивка руля та ручки КПП",
      "Двигун",
      "Інше",
    ],
    footer: [
      "Співробітники нашої компанії доклали всіх можливих зусиль, щоб інформація, представлена в прайс-листі, була достовірною і актуальною, проте можливі невеликі затримки в оновленні даних. Тому некоректна інформація на сайті не може бути підставою для претензій. У разі будь-яких сумнівів, будь ласка, зв'яжіться зі співробітником компанії перед прийняттям рішення. Пропозиція, представлена в прайс-листі, не є офертою в розумінні Цивільного кодексу, а тільки запрошенням до початку переговорів (відповідно до статті 71 Цивільного кодексу).",
      "© 2023 Carking | Serwis samochodowy Szczecin",
    ],
    table: [
      [
        "Гальмівна система",
        "Роботи по двигуну",
        "Автомеханіка",
        "Ремонт системи приводу та аксесуарів двигуна",
        "Ремонт рульового управління та підвіски",
        "Ремонт вихлопної системи",
        "Прайс",
        "Ціна",
      ],
    ],

    prices: [
      [
        {
          id: 1,
          name: "Заміна передніх гальмівних колодок",
          price: 100,
        },
        { id: 2, name: "Заміна задніх гальмівних колодок ", price: 100 },
        { id: 3, name: "Заміна гальмівних дисків", price: 100 },
        { id: 4, name: "Заміна гальмівних барабанів", price: 120 },
        { id: 5, name: "Заміна гальмівного циліндра", price: 60 },
        { id: 6, name: "Заміна гальмівних шлангів", price: 120 },
        { id: 7, name: "Заміна головного циліндра", price: 200 },
        { id: 8, name: "Заміна троса ручного гальма", price: 70 },
        { id: 9, name: "Регулювання ручного гальма", price: 50 },
        { id: 10, name: "Ремонт супорта", price: 120 },
      ],
      [
        { id: 1, name: "Регулювання клапанів двигуна", price: 240 },
        { id: 2, name: "Заміна котушки запалювання", price: 40 },
        { id: 3, name: "Заміна високовольтних проводів", price: 50 },
        { id: 4, name: "Заміна свічок", price: 40 },
        { id: 5, name: "Заміна свічок розжарювання", price: 80 },
      ],
      [
        { id: 1, name: "Заміна масла з фільтром", price: 120 },
        { id: 2, name: "Заміна повітряного фільтра", price: 30 },
        { id: 3, name: "Заміна паливного фільтра", price: 60 },
        { id: 4, name: " Заміна салонного фільтра", price: 40 },
        { id: 5, name: " Заміна гальмівної рідини", price: 120 },
        { id: 6, name: "Заміна охолоджуючої рідини", price: 50 },
        { id: 7, name: " Заміна масла в МКПП", price: 80 },
        {
          id: 8,
          name: "Заміна масла в АКПП",
          price: 300,
        },
        {
          id: 9,
          name: "Заміна масла в системі гідропідсилювача керма",
          price: 60,
        },
      ],
      [
        { id: 1, name: "Заміна зчеплення", price: 500 },
        { id: 2, name: " Заміна ГРМ", price: 450 },
        { id: 3, name: " Заміна водяного насоса", price: 150 },
        { id: 4, name: " Заміна обводного ременя ", price: 60 },
        { id: 5, name: " Заміна прокладки головки", price: 1000 },
        {
          id: 6,
          name: "Ремонт двигуна",
          price: 2000,
        },
        { id: 7, name: "Заміна турбокомпресора", price: 300 },
        {
          id: 8,
          name: "Заміна прокладки поддону",
          price: 100,
        },
        { id: 9, name: "Заміна термостата", price: 70 },
      ],
      [
        { id: 1, name: "Заміна переднього амортизатора", price: 150 },
        { id: 2, name: "Заміна заднього амортизатора", price: 70 },
        { id: 3, name: "Заміна передніх пружин 1 шт.", price: 150 },
        { id: 4, name: "Заміна задніх пружин 1 шт.", price: 70 },
        { id: 5, name: "Заміна рульової тяги 1 шт.", price: 80 },
        {
          id: 6,
          name: " Заміна наконечника рульової тяги 1 шт.",
          price: 50,
        },
        { id: 7, name: "Заміна тяги стабілізатора 1 шт.", price: 50 },
        {
          id: 8,
          name: "Заміна сайлентблок стабілізатора 1 шт.",
          price: 40,
        },
        { id: 9, name: "Заміна ричага 1 шт", price: 100 },
        { id: 10, name: "Заміна шарової 1 шт.", price: 75 },
        { id: 11, name: "Заміна насоса ГУР", price: 150 },
        { id: 12, name: "Заміна сайлентблоків 1 шт.", price: 70 },
        { id: 13, name: "Заміна переднього підшипника 1 шт.", price: 150 },
        { id: 14, name: "Заміна заднього підшипника 1 шт.", price: 150 },
        {
          id: 15,
          name: "Заміна внутрішньої гранати 1 шт.",
          price: 150,
        },
        { id: 16, name: "Заміна зовнішньої гранати 1 шт.", price: 120 },
        {
          id: 17,
          name: "Заміна пильовика внутрішної гранати 1 шт.",
          price: 150,
        },
        {
          id: 18,
          name: "Заміна пильовика зовнішньої гранати 1 шт",
          price: 150,
        },
        { id: 19, name: "Заміна рульової рейки", price: 300 },
        { id: 20, name: "Заміна рульової колонки", price: 200 },
        { id: 21, name: "Заміна подушки двигуна", price: 50 },
      ],
      [
        { id: 1, name: "Заміна всієї вихлопної системи", price: 200 },
        { id: 2, name: "Заміна глушника", price: 140 },
        { id: 3, name: "Заміна гофри", price: 100 },
        { id: 4, name: "Заміна клапана EGR", price: 100 },
        { id: 5, name: "Заміна лямбда-зонда", price: 80 },
        {
          id: 6,
          name: "Ремонт (зварювання) вихлопної системи",
          price: 100,
        },
      ],
    ],
  };
  pl = {
    nav: ["główna", "nasze usługi", "cennik", "kontakty"],
    slider: [
      [
        'Serwis samochodowy "CarKing"',
        "Gwarancja jakości",
        "Diagnostyka, naprawa samochodów, montaż opon",

        "Wygodna lokalizacja",
      ],
      ["Naprawa silników", "Naprawa silników o różnym stopniu skomplikowania"],
      [
        "Tuning",
        "- swap silników",
        "- polerowanie reflektorów",
        "- restauracja kierownic",
      ],
    ],
    services: [
      "Nowoczesne wyposażenie naszej firmy pozwała szybko diagnozować awarie i szybko je usuwać, gwarantując jakość. Nasz personel techniczny ma wysokie kwalifikacje, doświadczenie i wiedzę niezbędną do jakościowej naprawy o dowolnym stopniu trudności.",
    ],
    cardsServices: [
      [
        "Mechanik samochodowy",
        "wymiana oleju",
        "wymiana filtrów",
        "wymiana paska rozrządu",
      ],
      [
        "Serwis podwozia",
        "diagnoza",
        "wymiana podpór warstwowych",
        "wymiana dźwigni",
        "wymiana podkładek",
        "wymiana cichych bloków",
      ],
      [
        "Tuning samochodowy",
        "polerowanie reflektorów",
        "wymiana kierownicy",
        "swap",
      ],
      ["Wulkanizacja", "montaż opon balansowy"],
      ["Naprawa silników o dowolnej złożoności"],
      [
        "Wymiana sprzęgła automatycznej skrzyni biegów, manualnej skrzyni biegów",
      ],
    ],
    popServices: [
      "Nasz zespół doświadczonych mechaników świadczy usługi diagnostyki i naprawy silników. Szybko identyfikujemy i eliminujemy usterki, aby Twój samochód działał jak nowy. Zadbaj o bezpieczeństwo na drodze dzięki naszym usługom montażu i wyważania opon. Pomożemy Ci wybrać i prawidłowo dopasować opony, aby zapewnić maksymalną przyczepność i komfort podczas jazdy.",
    ],

    office: [
      "Rozumiemy, jak ważne jest Twoje bezpieczeństwo w podróży i wiemy, jak bardzo potrzebujesz samochodu na co dzień. Dlatego wszelkie naprawy lub konserwacje wykonują wyłącznie doświadczeni mechanicy samochodowi. Czekamy na Ciebie w naszym serwisie samochodowym w Szczecinie! Aby potwierdzić godzinę wizyty możesz zostawić prośbę na stronie lub zadzwonić do nas!",
      "Centrum specjalizuje się w kompleksowej naprawie samochodów, naprawie silników, tuningu i montażu opon.",
      "Specjalnie dla naszych klientów przygotowaliśmy przytulną przestrzeń, abyście mogli wygodnie odpocząć i miło spędzić czas w oczekiwaniu na swój samochód.",
      "Godziny pracy",
      "Poniedziałek",
      "Piątek",
      "Sobota",
      "Mapa dojazdu",
    ],
    buttons: [
      "Więcej o nas",
      "Umów się na konsultację",
      "Zostaw zgłoszenie serwisowe",
      "Wysłać",
      "poznaj cenę",
    ],
    titles: [
      "Nasze Usługi",
      "POPULARNE USŁUGI",
      "Trochę o nas",
      "ZŁOŻYĆ WNIOSEK",
      "Zostaw zapytanie o usługę, nasz menadżer skontaktuje się z Tobą i odpowie na wszystkie pytania.",
      "Kontakty serwisowe",
      "Cena",
    ],
    forms: [
      "Twoje imię",
      "Wybór usługi",
      "Utrzymanie samochodu",
      "Polerowanie lamp",
      "Obróbka okładziny kierownicy i gałki skrzyni biegów",
      "Silnik",
      "Inne",
    ],
    footer: [
      "„Pracownicy naszej firmy dołożyli wszelkich starań, aby informacje zawarte w cenniku były rzetelne i aktualne, jednakże mogą wystąpić niewielkie opóźnienia w aktualizacji danych. W związku z powyższym nieprawdziwe informacje zawarte na stronie internetowej nie mogą być podstawa do roszczeń.W przypadku jakichkolwiek wątpliwości przed podjęciem decyzji prosimy o kontakt z pracownikiem firmy.Oferta przedstawiona w cenniku nie jest ofertą w rozumieniu Kodeksu Cywilnego, a jedynie zaproszeniem do rozpoczęcia negocjacji ( zgodnie z art. 71 Kodeksu cywilnego).”",
      "© 2023 Carking | Serwis samochodowy Szczecin",
    ],
    table: [
      [
        "Układ hamulcowy",
        "Praca silnika",
        "Mechanik samochodowy",
        "Naprawa układu napędowego i osprzętu silnika",
        "Naprawa układu kierowniczego i zawieszenia",
        "Naprawa układu wydechowego",
        "Cena",
        "Cena",
      ],
    ],
    prices: [
      [
        {
          id: 1,
          name: "Wymiana klocków hamulcowych przód komplet",
          price: 100,
        },
        { id: 2, name: "Wymiana klocków hamulcowych tył komplet", price: 100 },
        { id: 3, name: "Wymiana tarcz hamulcowych 2 szt.", price: 100 },
        { id: 4, name: "Wymiana szczęk hamulcowych kompletod", price: 120 },
        { id: 5, name: "Wymiana cylinderka hamulcowego 1 szt.", price: 60 },
        { id: 6, name: "Wymiana przewodów hamulcowych komplet", price: 120 },
        { id: 7, name: "Wymiana pompy hamulcowej", price: 200 },
        { id: 8, name: "Wymiana linki hamulca ręczneg", price: 70 },
        { id: 9, name: "Regulacja hamulca ręcznego", price: 50 },
        { id: 10, name: " Uruchomienie zacisku hamulcowego", price: 120 },
      ],
      [
        { id: 1, name: "Regulacja zaworów silnikowych", price: 240 },
        { id: 2, name: "Wymiana cewki zapłonowej", price: 40 },
        { id: 3, name: "Wymiana przewodów zapłonowych", price: 50 },
        { id: 4, name: "Wymiana świec zapłonowych", price: 40 },
        { id: 5, name: "Wymiana świec żarowych 1 szt.", price: 80 },
      ],
      [
        { id: 1, name: "Wymiana oleju z filtrem ", price: 120 },
        { id: 2, name: "Wymiana filtra powietrza ", price: 30 },
        { id: 3, name: "Wymiana filtra paliwa", price: 60 },
        { id: 4, name: "Wymiana filtra kabinowego", price: 40 },
        { id: 5, name: "Wymiana płynu hamulcowego", price: 120 },
        { id: 6, name: "Wymiana płynu chłodzącego", price: 50 },
        { id: 7, name: "Wymiana oleju w manualnej skrzyni biegów", price: 80 },
        {
          id: 8,
          name: "Wymiana oleju w automatycznej skrzyni biegów",
          price: 300,
        },
        { id: 9, name: "Wymiana oleju w układzie wspomaganiat", price: 60 },
      ],
      [
        { id: 1, name: "Wymiana sprzęgła       ", price: 500 },
        { id: 2, name: "Wymiana rozrządu ", price: 450 },
        { id: 3, name: "Wymiana pompy wody ", price: 150 },
        { id: 4, name: "Wymiana paska klinowego 1 szt.", price: 60 },
        { id: 5, name: "Wymiana uszczelki pod głowicą ", price: 1000 },
        {
          id: 6,
          name: "Demontaż silnika – weryfikacja – naprawa",
          price: 2000,
        },
        { id: 7, name: "Wymiana turbosprężarki ", price: 300 },
        {
          id: 8,
          name: "Uszczelnienie miski olejowej ",
          price: 100,
        },
        { id: 9, name: "Wymiana termostatu", price: 70 },
      ],
      [
        { id: 1, name: "Wymiana amortyzatorów przód 1 szt", price: 150 },
        { id: 2, name: "Wymiana amortyzatorów tył 1 szt ", price: 70 },
        { id: 3, name: " Wymiana sprężyn przód 1 szt.", price: 150 },
        { id: 4, name: " Wymiana sprężyn tył 1 szt.", price: 70 },
        { id: 5, name: " Wymiana drążka kierowniczego 1 szt.", price: 80 },
        {
          id: 6,
          name: " Wymiana końcówki drążka kierowniczego 1 szt.",
          price: 50,
        },
        { id: 7, name: "Wymiana łącznika stabilizatora 1 szt.", price: 50 },
        {
          id: 8,
          name: "  Wymiana gumy stabilizatora 1 szt.",
          price: 40,
        },
        { id: 9, name: "  Wymiana wahacza 1 szt", price: 100 },
        { id: 10, name: "Wymiana sworznia wahacza 1 szt.", price: 75 },
        { id: 11, name: "Wymiana pompy wspomagania", price: 150 },
        { id: 12, name: " Wymiana tulei metalowo-gumowych 1 szt.", price: 70 },
        { id: 13, name: " Wymiana łożyska przód 1 szt.", price: 150 },
        { id: 14, name: " Wymiana łożyska tył 1 szt.", price: 150 },
        {
          id: 15,
          name: " Wymiana przegubu wewnętrznego 1 szt.",
          price: 150,
        },
        { id: 16, name: "Wymiana przegubu zewnętrznego 1 szt.", price: 120 },
        {
          id: 17,
          name: "Wymiana osłon przegubów wewnętrznych 1 szt.",
          price: 150,
        },
        {
          id: 18,
          name: "Wymiana osłon przegubów zewnętrznych 1 szt",
          price: 150,
        },
        { id: 19, name: "Wymiana przekładni kierowniczej", price: 300 },
        { id: 20, name: "Wymiana kolumny kierowniczej ", price: 200 },
        { id: 21, name: "Wymiana poduszki silnikaod", price: 50 },
      ],
      [
        { id: 1, name: "Wymiana kompletnego układu wydechowego", price: 200 },
        { id: 2, name: "Wymiana  tłumika", price: 140 },
        { id: 3, name: "Wymiana złącza elastycznego", price: 100 },
        { id: 4, name: "Wymiana zaworu EGR", price: 100 },
        { id: 5, name: "Wymiana sondy lambda", price: 80 },
        {
          id: 6,
          name: "Naprawa (spawanie) układu wydechowego",
          price: 100,
        },
      ],
    ],
  };
  eng = {
    nav: ["home", "services", "price", "contacts"],
    slider: [
      [
        'Auto service "CarKing"',
        "Quality guarantee",
        "Diagnosis,cars repair, tire fitting",
        "Convenient location",
      ],
      ["Engine repair", "Engine repair of any complexity"],
      [
        "Tuning",
        "- engine swaps",
        "- headlight polishing",
        "- restoration of steering wheels",
      ],
    ],
    services: [
      "Our company's modern equipment allows us to quickly diagnose issues and efficiently resolve them, providing a quality guarantee. Our technical staff is highly skilled and experienced, possessing the necessary knowledge for quality repairs of any complexity.",
    ],
    cardsServices: [
      [
        "Auto mechanics",
        "оil change with filter",
        "filter replacement",
        "replacing the timing belt",
      ],
      [
        "Maintenance of the undercarriage",
        "diagnosis",
        "replacement of bearing support",
        "replacement of levers",
        "pad replacement",
        "replacement of silent blocks",
      ],
      ["Tuning", "light polishing", "steering wheel re-stitching", "swaps"],
      ["Vulcanization", "tyre mounting", "balancing"],
      ["Repair of motors of any complexity"],
      ["Replacing the automatic transmission clutch, manual transmission"],
    ],
    popServices: [
      "Our team of experienced mechanics provides engine diagnostics and repair services. We promptly identify and eliminate malfunctions to keep your car running like new. Keep the road safe with our tire fitting and balancing services. We will help you choose and fit tires correctly to ensure maximum grip and comfort while driving.",
    ],
    office: [
      "We understand how important your safety during travels is, and we know how essential for you to have a car every day. That's why all repairs and maintenance are carried out by experienced auto mechanics. We look forward to seeing you at our auto service in Szczecin! To confirm your visit time, you can submit a request on our website or give us a call!",
      "The center specializes in comprehensive car repair services, auto electronics, engine repair, tuning and tire fitting.",
      "Especially for our clients, we have prepared a cozy area where you can comfortably relax and enjoy your time while waiting for your car.",
      "Working hours",
      "Monday",
      "Friday",
      "Saturday",
      "Directions Map",
    ],
    buttons: [
      "More about us",
      "Schedule a consultation",
      "Submit a request",
      "Send",
      "prices",
    ],
    titles: [
      "our services",
      "popular services",
      "about us",
      "Leave a request",
      "Leave a request, our manager will contact you and answer all your questions.",
      "Contact information for the service",
      "Price",
    ],
    forms: [
      "Your name",
      "Service selection",
      "Car maintenance",
      "Headlight polishing",
      "Recover the steering wheel covers and gear knob",
      "Engine",
      "Other",
    ],
    footer: [
      "Our company's employees have made every possible effort to ensure that the information presented in the price list is accurate and up to date. However, slight delays in data updates may occur. Therefore, incorrect information on the website cannot be the basis for claims. In case of any doubts, please contact a company representative before making a decision. The offer presented in the price list is not considered an offer under the Civil Code but rather an invitation to commence negotiations (in accordance with Article 71 of the Civil Code).",
      "© 2023 Carking | Auto servis Szczecin",
    ],
    table: [
      [
        "Układ hamulcowy",
        "Praca silnika",
        "Mechanik samochodowy",
        "Naprawa układu napędowego i osprzętu silnika",
        "Naprawa układu kierowniczego i zawieszenia",
        "Naprawa układu wydechowego",
        "Cena",
        "Cena",
      ],
    ],
    prices: [
      [
        {
          id: 1,
          name: "Wymiana klocków hamulcowych przód komplet",
          price: 100,
        },
        { id: 2, name: "Wymiana klocków hamulcowych tył komplet", price: 100 },
        { id: 3, name: "Wymiana tarcz hamulcowych 2 szt.", price: 100 },
        { id: 4, name: "Wymiana szczęk hamulcowych kompletod", price: 120 },
        { id: 5, name: "Wymiana cylinderka hamulcowego 1 szt.", price: 60 },
        { id: 6, name: "Wymiana przewodów hamulcowych komplet", price: 120 },
        { id: 7, name: "Wymiana pompy hamulcowej", price: 200 },
        { id: 8, name: "Wymiana linki hamulca ręczneg", price: 70 },
        { id: 9, name: "Regulacja hamulca ręcznego", price: 50 },
        { id: 10, name: " Uruchomienie zacisku hamulcowego", price: 120 },
      ],
      [
        { id: 1, name: "Regulacja zaworów silnikowych", price: 240 },
        { id: 2, name: "Wymiana cewki zapłonowej", price: 40 },
        { id: 3, name: "Wymiana przewodów zapłonowych", price: 50 },
        { id: 4, name: "Wymiana świec zapłonowych", price: 40 },
        { id: 5, name: "Wymiana świec żarowych 1 szt.", price: 80 },
      ],
      [
        { id: 1, name: "Wymiana oleju z filtrem ", price: 120 },
        { id: 2, name: "Wymiana filtra powietrza ", price: 30 },
        { id: 3, name: "Wymiana filtra paliwa", price: 60 },
        { id: 4, name: "Wymiana filtra kabinowego", price: 40 },
        { id: 5, name: "Wymiana płynu hamulcowego", price: 120 },
        { id: 6, name: "Wymiana płynu chłodzącego", price: 50 },
        { id: 7, name: "Wymiana oleju w manualnej skrzyni biegów", price: 80 },
        {
          id: 8,
          name: "Wymiana oleju w automatycznej skrzyni biegów",
          price: 300,
        },
        { id: 9, name: "Wymiana oleju w układzie wspomaganiat", price: 60 },
      ],
      [
        { id: 1, name: "Wymiana sprzęgła       ", price: 500 },
        { id: 2, name: "Wymiana rozrządu ", price: 450 },
        { id: 3, name: "Wymiana pompy wody ", price: 150 },
        { id: 4, name: "Wymiana paska klinowego 1 szt.", price: 60 },
        { id: 5, name: "Wymiana uszczelki pod głowicą ", price: 1000 },
        {
          id: 6,
          name: "Demontaż silnika – weryfikacja – naprawa",
          price: 2000,
        },
        { id: 7, name: "Wymiana turbosprężarki ", price: 300 },
        {
          id: 8,
          name: "Uszczelnienie miski olejowej ",
          price: 100,
        },
        { id: 9, name: "Wymiana termostatu", price: 70 },
      ],
      [
        { id: 1, name: "Wymiana amortyzatorów przód 1 szt", price: 150 },
        { id: 2, name: "Wymiana amortyzatorów tył 1 szt ", price: 70 },
        { id: 3, name: " Wymiana sprężyn przód 1 szt.", price: 150 },
        { id: 4, name: " Wymiana sprężyn tył 1 szt.", price: 70 },
        { id: 5, name: " Wymiana drążka kierowniczego 1 szt.", price: 80 },
        {
          id: 6,
          name: " Wymiana końcówki drążka kierowniczego 1 szt.",
          price: 50,
        },
        { id: 7, name: "Wymiana łącznika stabilizatora 1 szt.", price: 50 },
        {
          id: 8,
          name: "  Wymiana gumy stabilizatora 1 szt.",
          price: 40,
        },
        { id: 9, name: "  Wymiana wahacza 1 szt", price: 100 },
        { id: 10, name: "Wymiana sworznia wahacza 1 szt.", price: 75 },
        { id: 11, name: "Wymiana pompy wspomagania", price: 150 },
        { id: 12, name: " Wymiana tulei metalowo-gumowych 1 szt.", price: 70 },
        { id: 13, name: " Wymiana łożyska przód 1 szt.", price: 150 },
        { id: 14, name: " Wymiana łożyska tył 1 szt.", price: 150 },
        {
          id: 15,
          name: " Wymiana przegubu wewnętrznego 1 szt.",
          price: 150,
        },
        { id: 16, name: "Wymiana przegubu zewnętrznego 1 szt.", price: 120 },
        {
          id: 17,
          name: "Wymiana osłon przegubów wewnętrznych 1 szt.",
          price: 150,
        },
        {
          id: 18,
          name: "Wymiana osłon przegubów zewnętrznych 1 szt",
          price: 150,
        },
        { id: 19, name: "Wymiana przekładni kierowniczej", price: 300 },
        { id: 20, name: "Wymiana kolumny kierowniczej ", price: 200 },
        { id: 21, name: "Wymiana poduszki silnikaod", price: 50 },
      ],
      [
        { id: 1, name: "Wymiana kompletnego układu wydechowego", price: 200 },
        { id: 2, name: "Wymiana  tłumika", price: 140 },
        { id: 3, name: "Wymiana złącza elastycznego", price: 100 },
        { id: 4, name: "Wymiana zaworu EGR", price: 100 },
        { id: 5, name: "Wymiana sondy lambda", price: 80 },
        {
          id: 6,
          name: "Naprawa (spawanie) układu wydechowego",
          price: 100,
        },
      ],
    ],
  };
  data = this.pl;

  constructor() {
    makeAutoObservable(this);
  }
  switchLang(lang = "ua") {
    if (lang === "ua") {
      this.data = this.ua;
    } else if (lang === "pl") {
      this.data = this.pl;
    } else if (lang === "eng") {
      this.data = this.eng;
    }
  }
}

export default new Data();
