import { Divider } from "antd";
import { Link } from "react-router-dom";
import store from "../store/data";
import { observer } from "mobx-react-lite";
function Gas() {
  return (
    <div className="wrapper-container">
      <h2 id="repair" className="container__title">{store.data.titles[1]}</h2>
      <p className="container__subtitle">
      {store.data.popServices[0]}
      </p>
      <p className="container__subtitle">
      {store.data.popServices[1]}
      </p>
      <div className="gas-cards-container">
        
        <div className="gas-card">
          <h3 className="gas-card__title">нет динамики</h3>
          <div className="gas-card__price">
            <Divider />
            3200 zl <br />
            <p>за комплект з монтажем</p>
            <Divider />
          </div>
          <div className="gas-card__descr">
            <p>Контролер STAG Q-BOX або LPGTECH 204</p>
            <p>Інжектори ACW01</p>
            <p>Редуктор Tomasetto</p>
            <p>Бак до 55 л</p>
            <p>Встановлення та монтаж</p>
          </div>
          <Link to="/gas">
            <div className="gas-card__btn">дізнатися детальніше</div>
          </Link>
        </div>
        <div className="gas-card">
          <h3 className="gas-card__title">4-циліндровий до 100 к.с.</h3>
          <div className="gas-card__price">
            <Divider />
            3200 zl <br />
            <p>за комплект з монтажем</p>
            <Divider />
          </div>
          <div className="gas-card__descr">
            <p>Контролер STAG Q-BOX або LPGTECH 204</p>
            <p>Інжектори ACW01</p>
            <p>Редуктор Tomasetto</p>
            <p>Бак до 55 л</p>
            <p>Встановлення та монтаж</p>
          </div>
          <Link to="/gas">
            <div className="gas-card__btn">дізнатися детальніше</div>
          </Link>
        </div>
        <div className="gas-card">
          <h3 className="gas-card__title">4-циліндровий до 100 к.с.</h3>
          <div className="gas-card__price">
            <Divider />
            3200 zl <br />
            <p>за комплект з монтажем</p>
            <Divider />
          </div>
          <div className="gas-card__descr">
            <p>Контролер STAG Q-BOX або LPGTECH 204</p>
            <p>Інжектори ACW01</p>
            <p>Редуктор Tomasetto</p>
            <p>Бак до 55 л</p>
            <p>Встановлення та монтаж</p>
          </div>
          <Link to="/gas">
            <div className="gas-card__btn">дізнатися детальніше</div>
          </Link>
        </div>
        <div className="gas-card">
          <h3 className="gas-card__title">4-циліндровий до 100 к.с.</h3>
          <div className="gas-card__price">
            <Divider />
            3200 zl <br />
            <p>за комплект з монтажем</p>
            <Divider />
          </div>
          <div className="gas-card__descr">
            <p>Контролер STAG Q-BOX або LPGTECH 204</p>
            <p>Інжектори ACW01</p>
            <p>Редуктор Tomasetto</p>
            <p>Бак до 55 л</p>
            <p>Встановлення та монтаж</p>
          </div>
          <Link to="/gas">
            <div className="gas-card__btn">дізнатися детальніше</div>
          </Link>
        </div>

      </div>
    </div>
  );
}

export default observer (Gas);
