import { useState } from "react";
import store from "../store/data";

        
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import { observer } from "mobx-react-lite";
import postData from "../mailer/Service";
const { Option } = Select;
const { TextArea } = Input;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}
const theme = {
  components: {
    Input: {
      colorTextPlaceholder: "#848484",
    },
    Select: {
      colorTextPlaceholder: "#848484",
      controlHeight: 38,
    },
    TextArea: {
      controlHeight: 138,
    },
  },
};
const FontsStyles = {
  height: "38px",
};

function Forms() {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
   
    postData(values)

    
  };

  return (
    <ConfigProvider theme={theme}>
      <Form
        className="footer-forms"
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <div className="footer-forms__left-clm">
          <Form.Item
            name="nickname"
            rules={[
              {
                required: true,
                message: "Enter your name",
                whitespace: true,
              },
            ]}
            style={FontsStyles}
          >
            <Input style={FontsStyles} placeholder={store.data.forms[0]} />
          </Form.Item>

          <Form.Item
            name="phone"
            style={FontsStyles}
            rules={[{ required: true, message: "Enter your telephone number" }]}
          >
            <Input
              placeholder="+48 123 456 78 90*"
              style={{ height: "38px", width: "100%" }}
            />
          </Form.Item>
          <Form.Item name="services">
            <Select placeholder={store.data.forms[1]}>
              <Option value={store.data.forms[2]}>{store.data.forms[2]}</Option>
              <Option value={store.data.forms[3]}>{store.data.forms[3]}</Option>
              <Option value={store.data.forms[4]}>{store.data.forms[4]}</Option>
              <Option value={store.data.forms[5]}>{store.data.forms[5]}</Option>
              <Option value={store.data.forms[6]}>{store.data.forms[6]}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("Should accept agreement")),
              },
            ]}
          >
            <Checkbox style={{ color: "#fff" }}>
              I have read the{" "}
              <i style={{ color: "#EBCF5F" }} onClick={() => setOpen(true)}>
                agreement
              </i>
            </Checkbox>
          </Form.Item>
          <Modal
            title="Danych osobowych"
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={600}
          >
            <p>
              Administracja zobowiązuje się do zachowania Twojej prywatności w
              Internecie. Przywiązujemy dużą wagę do ochrony przekazywanych
              przez Ciebie danych. Nasza polityka prywatności opiera się na
              wymogach ogólnego rozporządzenia Unii Europejskiej o ochronie
              danych (RODO). Cele, dla których zbieramy dane osobowe to: poprawa
              wydajności naszego serwisu, komunikacja z gośćmi tej strony,
              wysyłanie biuletynów pocztą elektroniczną, przetwarzanie danych
              użytkownika w ramach usługi handlu online, dostarczanie informacji
              wymaganych przez użytkownika, jak również za następujące
              działania. Gromadzenie i wykorzystywanie danych osobowych
              Gromadzimy i wykorzystujemy Twoje dane osobowe wyłącznie za Twoją
              dobrowolną zgodą. Jeśli wyrażasz na to zgodę, upoważniasz nas do
              gromadzenia i wykorzystywania następujących danych: imię i
              nazwisko, adres e-mail, numer telefonu, . Zbieranie i
              przetwarzanie Twoich danych odbywa się zgodnie z przepisami prawa
              obowiązującymi na terenie Unii Europejskiej oraz w
              Rzeczypospolitej Polskiej. Przechowywanie, modyfikacja i usuwanie
              danych Użytkownik, który udostępnił swoje dane osobowe w serwisie
              alma-auto.pl ma prawo do ich zmiany i usunięcia, a także wycofania
              zgody na ich wykorzystanie. Okres przechowywania Twoich danych
              osobowych: 6 miesięcy. Po zakończeniu korzystania z Twoich danych
              administracja serwisu je usuwa. Twoje dane osobowe możemy
              przekazać podmiotowi trzeciemu tylko za Twoją dobrowolną zgodą,
              jeżeli zostały przekazane, to nie możemy zmieniać danych w innych,
              niepowiązanych z nami organizacjach. Wykorzystanie danych
              technicznych podczas odwiedzania strony Gdy odwiedzasz witrynę
              alma-auto.pl, w bazie danych rejestrowany jest Twój adres IP, czas
              wizyty, ustawienia przeglądarki, system operacyjny oraz inne
              informacje techniczne niezbędne do prawidłowego wyświetlania
              zawartości witryny. Na podstawie tych danych nie jesteśmy w stanie
              zidentyfikować tożsamości odwiedzającego. Udzielanie informacji
              przez dzieci Jeśli jesteś rodzicem lub opiekunem i wiesz, że Twoje
              dzieci przekazały nam swoje dane osobowe bez Twojej zgody,
              skontaktuj się z nami: kontakt@alma-auto.pl. W naszym serwisie
              zabronione jest pozostawianie danych osobowych osób nieletnich bez
              zgody rodziców lub opiekunów. Korzystanie z plików cookie Używamy
              plików cookies, aby zapewnić prawidłowe wyświetlanie treści oraz
              wygodę korzystania z alma-auto.pl. Są to małe pliki, które są
              przechowywane na Twoim urządzeniu. Pomagają witrynie zapamiętać
              informacje o Tobie, takie jak język, w jakim przeglądasz witrynę i
              jakie strony już otworzyłeś, informacje te będą przydatne przy
              następnej wizycie. Dzięki plikom cookies przeglądanie serwisu
              staje się dużo wygodniejsze. Możesz przeczytać więcej o tych
              plikach tutaj. Możesz samodzielnie skonfigurować swoją
              przeglądarkę tak, aby akceptowała lub blokowała pliki cookie. Brak
              akceptacji plików cookies może ograniczyć funkcjonalność serwisu.
              Wykorzystanie danych osobowych przez inne usługi Ta strona
              korzysta z zewnętrznych usług internetowych, które zbierają
              informacje niezależnie od nas: Google Analytics, Yandex.Metrica,
              Facebook.com. Gromadzone przez nich dane mogą być udostępniane
              innym usługom w ramach tych organizacji i mogą wykorzystywać te
              dane do personalizowania reklam z własnej sieci reklamowej. Możesz
              przeczytać umowy użytkownika tych organizacji na ich stronach
              internetowych. Tam możesz również odmówić zbierania od nich danych
              osobowych, na przykład tutaj znajduje się bloker Google Analytics,
              tutaj znajduje się bloker Yandex Metrics. Nie udostępniamy danych
              osobowych innym organizacjom i usługom niewymienionym w niniejszej
              polityce prywatności. Jedynym wyjątkiem jest przekazywanie
              informacji w ramach wymogów prawnych organów państwowych
              uprawnionych do wykonywania tych czynności. Linki do innych stron
              Nasza typowa witryna alma-auto.pl może zawierać linki do innych
              witryn, które nie są przez nas obsługiwane. Nie ponosimy
              odpowiedzialności za ich treść. Zachęcamy do zapoznania się z
              polityką prywatności każdej odwiedzanej witryny, jeśli jest
              dostępna. Zmiany w polityce prywatności Nasza witryna internetowa
              alma-auto.pl może od czasu do czasu aktualizować naszą politykę
              prywatności. Powiadomimy Cię o wszelkich zmianach, publikując nową
              politykę prywatności na tej stronie. Monitorujemy zmiany w
              ustawodawstwie dotyczącym danych osobowych w Unii Europejskiej
              oraz w Polsce. Jeżeli pozostawiłeś nam dane osobowe, poinformujemy
              Cię o zmianie polityki prywatności. Jeśli Twoje dane osobowe
              zostały wprowadzone błędnie, nie będziemy mogli się z Tobą
              skontaktować.
            </p>
          </Modal>
          <Form.Item>
            <Button
              style={{
                color: "#000",
                backgroundColor: "#EBCF5F",
                fontWeight: 600,
              }}
              type="primary"
              htmlType="submit"
            >
              {store.data.buttons[3]}
            </Button>
          </Form.Item>
          {/*  <Form.Item name="intro">
            <TextArea
              placeholder="Ваш коментар"
              showCount
              maxLength={100}
              style={{ height: 100 }}
            />
          </Form.Item> */}
        </div>

        <div className="footer-forms__right-clm">
          {/* <Form.Item name="office">
            <Select placeholder="Вибір автосервісу">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
            </Select>
          </Form.Item> */}
        </div>

        {/*  <Form.Item
        label="Captcha"
        extra="We must make sure that your are a human."
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="captcha"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please input the captcha you got!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button>Get captcha</Button>
          </Col>
        </Row>
      </Form.Item> */}
      </Form>
    </ConfigProvider>
  );
}

export default observer (Forms);
